<template>
  <div class="page-container product-price-tables-page">
    <portal to="page-name">Tabelas de preços</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Tabelas de preços</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search" class="form-input"
                     v-model="filter.search" placeholder="Informe o nome para pesquisar">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <button class="btn btn-primary btn-icon btn-icon-left" @click="create">
              <fa-icon :icon="['fal', 'plus']"/>Nova tabela
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading" />
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Tabelas de preços</p>
              <p class="empty-subtitle">
                Nenhuma tabela encontrada. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th>Nome da tabela</th>
                <th style="width: 100px">Material</th>
                <th style="width: 100px">Medicamento</th>
                <th style="width: 100px">Uso restrito</th>
                <th style="width: 50px"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in data.items" :key="i">
                <td style="width:150px">
                  <router-link :to="editLink(item)">
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  <span v-if="item.material">
                    {{ getItemType(item.material) }}: {{ item.material.value | number }}%
                  </span>
                </td>
                <td>
                  <span v-if="item.medicine">
                    {{ getItemType(item.medicine) }}: {{ item.medicine.value | number }}%
                  </span>
                </td>
                <td>
                  <span v-if="item.restricted">
                    {{ getItemType(item.restricted) }}: {{ item.restricted.value | number }}%
                  </span>
                </td>
                <td class="text-right">
                  <button class="btn btn-sm btn-action btn-icon"
                          :class="item.own ? 'btn-neutral' : 'btn-secondary'"
                          @click="edit(item)">
                    <fa-icon :icon="['fal', item.own ? 'eye' : 'pencil']"></fa-icon>
                  </button>
                  <button class="btn btn-sm btn-error btn-icon btn-action ml-1"
                          v-if="!item.own"
                          :class="{loading: deleting}"
                          @click="remove(item.id, i)"
                          :disabled="deleting">
                    <fa-icon :icon="['fal', 'times']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { DELETE_PRICE_TABLE } from '@/data/actions/modules/stock';

export default {
  data() {
    return {
      path: '/product-price-tables',
      debounceTimeout: null,
      loading: false,
      deleting: false,
      filter: {
        search: '',
      },
      data: {
        items: [],
        total: 0,
        limit: 0,
        offset: 0,
      },
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.search, 600);
      },
      deep: true,
    },
  },
  computed: {
    canAccessRemove() {
      if (DELETE_PRICE_TABLE) {
        return this.$can(DELETE_PRICE_TABLE);
      }
      return true;
    },
  },
  methods: {
    search() {
      this.data.offset = 0;
      this.load();
    },
    load() {
      this.loading = true;

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    create() {
      this.$router.push('/stock/price-tables/create');
    },
    editLink(item) {
      return `/stock/price-tables/${item.id}/edit`;
    },
    edit(item) {
      this.$router.push(`/stock/price-tables/${item.id}/edit`);
    },
    remove(id, i) {
      if (!this.canAccessRemove) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este registro?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.$http
                .delete(`/product-price-tables/${id}`)
                .then(() => {
                  this.data.items.splice(i, 1);
                })
                .catch(() => {
                })
                .finally(() => {
                  this.deleting = false;
                  close();
                });
            },
          },
        ],
      });
    },
    getItemType(item) {
      const name = '-';

      if (!item || !item.table) {
        return name;
      }

      if (item.table === 'brasindice') {
        return 'Brasíndice';
      }

      if (item.table === 'simpro') {
        return 'Simpro';
      }

      return 'Tabela própria';
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.product-price-tables-page {
}
</style>
