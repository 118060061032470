<template>
  <div class="page-container stock-price-table-page">
    <portal to="page-name">Tabela de preço</portal>
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="card-title">Tabela de preço</div>
      </div>
      <div class="card-body">
        <div :class="{'has-error': $v.form.name.$error}">
          <label for="name" class="form-label">Nome</label>
          <input id="name" class="form-input"
                 :readonly="form.own" v-model="form.name"
                 @blur="$v.form.name.$touch()"/>
          <template v-if="$v.form.name.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.name.required">Campo obrigatório</div>
          </template>
        </div>

        <div class="divider" />

        <h6>Regras</h6>

        <div class="rules">
          <div class="rule-group">
            <strong>Materiais</strong>
            <div class="columns mt-2">
              <div class="column col-12 form-group"
                   :class="{'has-error': $v.form.material.table.$error}">
                <label class="form-label">Tabela</label>
                <select class="form-select"
                        :disabled="form.own"
                        v-model="form.material.table"
                        @change="clearValues"
                        @blur="$v.form.material.table.$touch()">
                  <option value="">[Selecione]</option>
                  <option value="brasindice">Brasíndice</option>
                  <option value="simpro">Simpro</option>
                  <option value="own_table">Tabela própria</option>
                </select>
              </div>
              <div class="column col-12 form-group"
                   :class="{'has-error': $v.form.material.type.$error}">
                <label class="form-label">Tipo</label>
                <select class="form-select"
                        :disabled="form.own || form.material.table === 'own_table'"
                        v-model="form.material.type"
                        @change="clearValues"
                        @blur="$v.form.material.type.$touch()">
                  <option value="">[Selecione]</option>
                  <option value="PFB">PFB</option>
                  <option value="PMC">PMC</option>
                </select>
              </div>
              <div class="column col-12 form-group"
                   :class="{'has-error': $v.form.material.value.$error}">
                <label class="form-label">Percentual</label>
                <div class="input-group">
                  <dx-input-number
                    class="form-input text-right"
                    :readonly="form.own"
                    :disabled="form.material.table === 'own_table'"
                    v-model="form.material.value"
                    @blur="$v.form.material.value.$touch()"
                    :precision="2"
                    maxlength="6"
                  />
                  <span class="input-group-addon">%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="rule-group">
            <strong>Medicamentos e soluções</strong>
            <div class="columns mt-2">
              <div class="column col-12 form-group"
                   :class="{'has-error': $v.form.medicine.table.$error}">
                <label class="form-label">Tabela</label>
                <select class="form-select"
                        :disabled="form.own"
                        v-model="form.medicine.table"
                        @change="clearValues"
                        @blur="$v.form.medicine.table.$touch()">
                  <option value="">[Selecione]</option>
                  <option value="brasindice">Brasíndice</option>
                  <option value="simpro">Simpro</option>
                  <option value="own_table">Tabela própria</option>
                </select>
              </div>
              <div class="column col-12 form-group"
                   :class="{'has-error': $v.form.medicine.type.$error}">
                <label class="form-label">Tipo</label>
                <select class="form-select"
                        :disabled="form.own || form.medicine.table === 'own_table'"
                        v-model="form.medicine.type"
                        @change="clearValues"
                        @blur="$v.form.medicine.type.$touch()">
                  <option value="">[Selecione]</option>
                  <option value="PFB">PFB</option>
                  <option value="PMC">PMC</option>
                </select>
              </div>
              <div class="column col-12 form-group"
                   :class="{'has-error': $v.form.medicine.value.$error}">
                <label class="form-label">Percentual</label>
                <div class="input-group">
                  <dx-input-number
                    class="form-input text-right"
                    v-model="form.medicine.value"
                    :readonly="form.own"
                    :disabled="form.medicine.table === 'own_table'"
                    @blur="$v.form.medicine.value.$touch()"
                    :precision="2"
                    maxlength="6"
                  />
                  <span class="input-group-addon">%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="rule-group">
            <strong>Uso restrito</strong>
            <div class="columns mt-2">
              <div class="column col-12 form-group"
                   :class="{'has-error': $v.form.restricted.table.$error}">
                <label class="form-label">Tabela</label>
                <select class="form-select"
                        :disabled="form.own"
                        v-model="form.restricted.table"
                        @change="clearValues"
                        @blur="$v.form.restricted.table.$touch()">
                  <option value="">[Selecione]</option>
                  <option value="brasindice">Brasíndice</option>
                  <option value="simpro">Simpro</option>
                  <option value="own_table">Tabela própria</option>
                </select>
              </div>
              <div class="column col-12 form-group"
                   :class="{'has-error': $v.form.restricted.type.$error}">
                <label class="form-label">Tipo</label>
                <select class="form-select"
                        :disabled="form.own || form.restricted.table === 'own_table'"
                        v-model="form.restricted.type"
                        @change="clearValues"
                        @blur="$v.form.restricted.type.$touch()">
                  <option value="">[Selecione]</option>
                  <option value="PFB">PFB</option>
                  <option value="PMC">PMC</option>
                </select>
              </div>
              <div class="column col-12 form-group"
                   :class="{'has-error': $v.form.restricted.value.$error}">
                <label class="form-label">Percentual</label>
                <div class="input-group">
                  <dx-input-number
                    class="form-input text-right"
                    :readonly="form.own"
                    :disabled="form.restricted.table === 'own_table'"
                    v-model="form.restricted.value"
                    @blur="$v.form.restricted.value.$touch()"
                    :precision="2"
                    maxlength="6"
                  />
                  <span class="input-group-addon">%</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1"
                v-if="!form.own"
                @click="save"
                :disabled="saving"
                :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import { mergeFrom } from '@/helpers/object';
import formMixin from '../../../mixins/form';

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/product-price-tables',
      isNew: false,
      loading: false,
      saving: false,
      form: this.blankForm(),
    };
  },
  validations() {
    const rules = {
      form: {
        name: { required },
        medicine: {
          table: { required },
          type: {},
          value: { required },
        },
        material: {
          table: { required },
          type: {},
          value: { required },
        },
        restricted: {
          table: { required },
          type: {},
          value: { required },
        },
      },
    };
    if (this.form.medicine.table !== 'own_table') {
      rules.form.medicine.type = { required };
    }
    if (this.form.material.table !== 'own_table') {
      rules.form.material.type = { required };
    }
    if (this.form.restricted.table !== 'own_table') {
      rules.form.restricted.type = { required };
    }

    return rules;
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.saving = true;

      const priceTable = this.clone(this.form);

      const request = this.isNew
        ? this.$http.post(this.path, priceTable)
        : this.$http.put(`${this.path}/${this.form.id}`, priceTable);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          if (this.isNew) {
            this.$router.replace(`/stock/price-tables/${result.id}/edit`);
            this.isNew = false;
            this.form.id = result.id;
          }
          this.$toast.show('Registro salvo!');
        })
        .catch(() => {})
        .finally(() => {
          this.saving = false;
        });
    },
    clearValues() {
      if (this.form.material.table === 'own_table') {
        this.form.material.type = '';
        this.form.material.value = 0;
        this.$v.form.material.value.$reset();
      }
      if (this.form.medicine.table === 'own_table') {
        this.form.medicine.type = '';
        this.form.medicine.value = 0;
        this.$v.form.medicine.value.$reset();
      }
      if (this.form.restricted.table === 'own_table') {
        this.form.restricted.type = '';
        this.form.restricted.value = 0;
        this.$v.form.restricted.value.$reset();
      }
    },
    blankForm() {
      return {
        id: '',
        name: '',
        own: false,
        medicine: {
          table: '',
          type: '',
          value: '',
        },
        material: {
          table: '',
          type: '',
          value: '',
        },
        restricted: {
          table: '',
          type: '',
          value: '',
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.stock-price-table-page {
  .divider {
    margin: 1rem 0;
  }
  .rules {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: $layout-spacing-lg;
    .rule-group {
      background-color: $gray-color-ultra-light;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      padding: $layout-spacing $layout-spacing-lg;
    }
  }
}
</style>
